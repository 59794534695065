import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  CompanyRoleCreateDto,
  CompanyRoleDto,
  CompanyRoleListRequestDto,
  CompanyRoleListResultDto,
  CompanyRoleUpdateDto,
  CompanyUserListItemDto,
  CompanyUserRoleListItemDto
} from '@ifhms/models/admin';

@Injectable()
export class CompanyRoleService {
  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private http: HttpClient
  ) {
  }

  getList(companyId: string): Observable<CompanyUserRoleListItemDto[]> {
    return this.http.get<CompanyUserRoleListItemDto[]>(`${this.apiUrl}/Company/${companyId}/Roles/SelectList`);
  }

  getTable(companyId: string, request: CompanyRoleListRequestDto): Observable<CompanyRoleListResultDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        ...request
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<CompanyRoleListResultDto>(`${this.apiUrl}/Company/${companyId}/Roles/Table`, options);
  }

  get(companyId: string, id: string): Observable<CompanyRoleDto> {
    return this.http.get<CompanyRoleDto>(`${this.apiUrl}/Company/${companyId}/Roles/${id}`);
  }

  getRoleUsers(companyId: string, roleId: string): Observable<CompanyUserListItemDto[]> {
    return this.http.get<CompanyUserListItemDto[]>(`${this.apiUrl}/Company/${companyId}/Roles/Users/${roleId}`);
  }

  create(companyId: string, roleCreate: CompanyRoleCreateDto): Observable<CompanyRoleDto> {
    return this.http.post<CompanyRoleDto>(`${this.apiUrl}/Company/${companyId}/Roles`, roleCreate);
  }

  update(companyId: string, roleUpdate: CompanyRoleUpdateDto): Observable<CompanyRoleDto> {
    return this.http.put<CompanyRoleDto>(`${this.apiUrl}/Company/${companyId}/Roles`, roleUpdate);
  }

  isNameUnique(companyId: string, name: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: { name }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Company/${companyId}/Roles/IsNameUnique`, options);
  }
}
