import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { PermissionDeniedComponent } from './components';

const ROUTES: Route[] = [
  {
    path: 'permissions',
    component: PermissionDeniedComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class CommonAngularPermissionsRoutingModule {}
