import { FrmPermissionsLotBilling } from './frm-permissions-lot-billing.enum';
import { FrmPermissionsProductManagement } from './frm-permissions-product-management.enum';
import { FrmPermissionsReport } from './frm-permissions-reports.enum';
import { FrmPermissionsSettings } from './frm-permissions-settings.enum';
import { FrmPermissionsTreatmentProtocolsManagement } from './frm-permissions-treatment-protocol-management.enum';

export type FrmFeaturePermission =
  FrmPermissionsLotBilling
  | FrmPermissionsProductManagement
  | FrmPermissionsReport
  | FrmPermissionsSettings
  | FrmPermissionsTreatmentProtocolsManagement;

export const FrmFeaturePermission = {
  ...FrmPermissionsReport,
  ...FrmPermissionsSettings,
  ...FrmPermissionsLotBilling,
  ...FrmPermissionsProductManagement,
  ...FrmPermissionsTreatmentProtocolsManagement
};
