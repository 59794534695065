export enum FrmPermissionsProductManagement {
  ProductDefinitionView = 'frmPermission.canViewProductDefinition',
  ProductDefinitionEdit = 'frmPermission.canEditProductDefinition',
  ProductMappingView = 'frmPermission.canViewProductMapping',
  ProductMappingEdit = 'frmPermission.canEditProductMapping',
  ProductPricingView = 'frmPermission.canViewProductPricing',
  ProductPricingEdit = 'frmPermission.canEditProductPricing',
  ProductReceiptView = 'frmPermission.canViewProductReceipt',
  ProductReceiptEdit = 'frmPermission.canEditProductReceipt',
  ProductReconciliationView = 'frmPermission.canViewProductReconciliation',
  ProductReconciliationEdit = 'frmPermission.canEditProductReconciliation',
  ProductLotNoExpiryView = 'frmPermission.canViewProductLotNoExpiry',
  ProductTransactionView = 'frmPermission.canViewProductTransactions'
}
