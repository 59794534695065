import { flatten } from 'flat';

import { CommonPermissionsMap } from '@common/angular/permissions';
import { HashMap } from '@common/angular/interfaces';

export function transformPermission(permissionsMap: HashMap | null): CommonPermissionsMap | null {
  if (!permissionsMap) return null;

  const defaultPermissions = {};

  return { ...defaultPermissions, ...flatten(permissionsMap) };
}