import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, tap, withLatestFrom } from 'rxjs';
import { catchError, exhaustMap, filter, map } from 'rxjs/operators';

import { AdminPermissionsService } from '@ifhms/common/angular/data-access/admin-api';
import { transformPermission } from '@common/angular/utils';
import { OperationPermissionsService } from '@ifhms/admin/web/domain/permissions';
import { OperationFacade } from '@ifhms/admin/web/domain/state/operation';
import { CommonPermissionsMap, CommonPermissionsService } from '@common/angular/permissions';
import { OperationPermissionsDto } from '@ifhms/models/shared';
import { ModuleEnablementSettingsDto } from '@ifhms/models/admin';
import { ReportPermissionsEnum, SharedReportPermissionsData } from '@ifhms/common/angular/interfaces';

import { OperationPermissionsActions } from './operation-permissions.actions';

@Injectable()
export class OperationPermissionsEffects {

  constructor(
    private readonly actions$: Actions,
    private operationFacade: OperationFacade,
    private adminPermissionsService: AdminPermissionsService,
    private operationPermissionsService: OperationPermissionsService,
    private commonPermissionsService: CommonPermissionsService
  ) {}

  getOperationPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationPermissionsActions.getOperationPermissions),
      withLatestFrom(this.operationFacade.operation$),
      filter(([, operation]) => !!operation),
      exhaustMap(([, operation]) =>
        this.adminPermissionsService.getOperationPermissionsForUser(operation!.id).pipe(
          tap(permissions => this.setPermissions(permissions, operation!.moduleIntegrationSettings!)),
          map((operationPermissions) => OperationPermissionsActions.getOperationPermissionsSuccess({ operationPermissions })),
          catchError(err => of(OperationPermissionsActions.error(err)))
        )
      )
    )
  );

  private setPermissions(permissions: OperationPermissionsDto, enabledModules: ModuleEnablementSettingsDto): void {
    this.setOperationPermissions(permissions, enabledModules);
    this.setCommonPermissions(permissions);
  }

  private setOperationPermissions(permissions: OperationPermissionsDto, enabledModules: ModuleEnablementSettingsDto): void {
    // Make sure module is enabled for the current operation
    permissions.hasFrm &&= enabledModules.isFrmEnabled;
    permissions.hasProcurement &&= enabledModules.isProcurementsEnabled;

    const flatPermissions: CommonPermissionsMap = transformPermission(permissions)!;
    this.operationPermissionsService.setPermissions(flatPermissions);
  }

  private setCommonPermissions(permissions: OperationPermissionsDto): void {
    const reportsPermissions = this.getSharedReportsPermissions(permissions);
    this.commonPermissionsService.setPermissions(reportsPermissions);
  }

  private getSharedReportsPermissions(permissions: OperationPermissionsDto): SharedReportPermissionsData {
    return<SharedReportPermissionsData> {
      [ReportPermissionsEnum.ReportsAnimalTreatmentHistory]: permissions.frmPermission.canViewAnimalTreatmentHistoryReport,
      [ReportPermissionsEnum.ReportsArrivalWorkOrder]: permissions.frmPermission.canViewArrivalWorkOrderReport,
      [ReportPermissionsEnum.ReportsCattleMovement]: permissions.frmPermission.canViewCattleMovementReport,
      [ReportPermissionsEnum.ReportsCurrentProductPricing]: permissions.frmPermission.canViewCurrentProductPricingReport,
      [ReportPermissionsEnum.ReportsDailyTreatmentHistoryByFacility]: permissions.frmPermission.canViewDailyTreatmentHistoryByFacilityReport,
      [ReportPermissionsEnum.ReportsEventDetails]: permissions.frmPermission.canViewEventDetailsReport,
      [ReportPermissionsEnum.ReportsHospitalOccupancy]: permissions.frmPermission.canViewHospitalOccupancyReport,
      [ReportPermissionsEnum.ReportsLotExpensesSummary]: permissions.frmPermission.canViewLotExpensesSummaryReport,
      [ReportPermissionsEnum.ReportsLotHealthSummary]: permissions.frmPermission.canViewLotHealthSummaryReport,
      [ReportPermissionsEnum.ReportsLotOtherDiseasesDetail]: permissions.frmPermission.canViewLotOtherDiseasesDetailReport,
      [ReportPermissionsEnum.ReportsLotTransferWorkOrder]: permissions.frmPermission.canViewLotTransferWorkOrderReport,
      [ReportPermissionsEnum.ReportsLotUFDetail]: permissions.frmPermission.canViewLotUfDetailReport,
      [ReportPermissionsEnum.ReportsLotInventoryReport]: permissions.frmPermission.canViewLotInventoryReport,
      [ReportPermissionsEnum.ReportsMovementWorkOrder]: permissions.frmPermission.canViewMovementWorkOrderReport,
      [ReportPermissionsEnum.ReportsOtherDiseasesManagement]: permissions.frmPermission.canViewOtherDiseasesManagementReport,
      [ReportPermissionsEnum.ReportsPenInventory]: permissions.frmPermission.canViewPenInventoryReport,
      [ReportPermissionsEnum.ReportsPostMortemDetail]: permissions.frmPermission.canViewPostMortemDetailReport,
      [ReportPermissionsEnum.ReportsPostMortemDetailByDate]: permissions.frmPermission.canViewPostMortemDetailByDateReport,
      [ReportPermissionsEnum.ReportsProductOnHand]: permissions.frmPermission.canViewProductOnHandReport,
      [ReportPermissionsEnum.ReportsProductUsage]: permissions.frmPermission.canViewProductUsageReport,
      [ReportPermissionsEnum.ReportsRailDetail]: permissions.frmPermission.canViewRailDetailReport,
      [ReportPermissionsEnum.ReportsRailWorkOrder]: permissions.frmPermission.canViewRailWorkOrderReport,
      [ReportPermissionsEnum.ReportsReconciledPhysicalInventoryValue]: permissions.frmPermission.canViewReconciledPhysicalInventoryValueReport,
      [ReportPermissionsEnum.ReportsReHandlingWorkOrder]: permissions.frmPermission.canViewRehandlingWorkOrderReport,
      [ReportPermissionsEnum.ReportsShipmentWorkOrder]: permissions.frmPermission.canViewShipmentWorkOrderReport,
      [ReportPermissionsEnum.ReportsTagAvailability]: permissions.frmPermission.canViewTagAvailabilityReport,
      [ReportPermissionsEnum.ReportsUFManagement]: permissions.frmPermission.canViewUfManagementReport,
      [ReportPermissionsEnum.ReportsWithdrawal]: permissions.frmPermission.canViewWithdrawalReport
    };
  }

}
