import { Injectable } from '@angular/core';
import {
  FeedlotGeneralSettingsDto,
  FeedlotGeneralSettingsUpdateDto,
  ModuleEnablementSettingsDto,
  OperationDto,
  OperationSettingsCciaDto,
  OperationSettingsLocationDto,
  OperationSettingsPmIntegrationDto,
  OperationSettingsSortGroupDto,
  OperationSettingsTagsDto
} from '@ifhms/models/admin';
import { OperationPermissionsDto } from '@ifhms/models/shared';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { OperationActions } from './operation.actions';
import * as reducer from './operation.reducer';
import { operationSelectors } from './operation.selectors';

@Injectable({ providedIn: 'root' })
export class OperationFacade {
  state$: Observable<reducer.State> = this.store.select(
    operationSelectors.selectState
  );
  loading$: Observable<boolean> = this.store.select(
    operationSelectors.selectLoading
  );
  loaded$: Observable<boolean> = this.store.select(
    operationSelectors.selectLoaded
  );
  updating$: Observable<boolean> = this.store.select(
    operationSelectors.selectUpdating
  );
  updated$: Observable<boolean> = this.store.select(
    operationSelectors.selectUpdated
  );
  operation$: Observable<OperationDto | null> = this.store.select(
    operationSelectors.selectOperation
  );
  operationId$: Observable<string> = this.store.select(
    operationSelectors.selectOperationId
  );
  operationName$: Observable<string> = this.store.select(
    operationSelectors.selectOperationName
  );
  operationSlug$: Observable<string> = this.store.select(
    operationSelectors.selectOperationSlug
  );
  permissions$: Observable<OperationPermissionsDto | null> = this.store.select(
    operationSelectors.selectPermissions
  );

  // Settings
  generalSettings$: Observable<FeedlotGeneralSettingsDto | null> =
    this.store.select(operationSelectors.selectGeneralSettings);
  location$: Observable<OperationSettingsLocationDto | null> =
    this.store.select(operationSelectors.selectLocation);
  tagSettings$: Observable<OperationSettingsTagsDto | null> = this.store.select(
    operationSelectors.selectTagSettings
  );
  sortGroupSettings$: Observable<OperationSettingsSortGroupDto | null> =
    this.store.select(operationSelectors.selectSortGroupSettings);
  cciaAccountSettings$: Observable<OperationSettingsCciaDto | null> =
    this.store.select(operationSelectors.selectCciaAccountSettings);
  pmIntegrationSettings$: Observable<OperationSettingsPmIntegrationDto | null> =
    this.store.select(operationSelectors.selectPmIntegrationSettings);
  moduleIntegrationSettings$: Observable<ModuleEnablementSettingsDto | null> =
    this.store.select(operationSelectors.selectModuleIntegrationSettings);

  constructor(private store: Store<reducer.State>) {
  }

  get(operationId: string): void {
    this.store.dispatch(OperationActions.get({ operationId }));
  }

  getBySlug(slug: string, updateCurrent = false): void {
    this.store.dispatch(OperationActions.getBySlug({ slug, updateCurrent }));
  }

  getGeneralSettings(): void {
    this.store.dispatch(OperationActions.getGeneralSettings());
  }

  updateGeneralSettings(
    generalSettingsUpdate: FeedlotGeneralSettingsUpdateDto
  ): void {
    this.store.dispatch(
      OperationActions.updateGeneralSettings({ generalSettingsUpdate })
    );
  }

  resetGeneralSettings(): void {
    this.store.dispatch(OperationActions.resetGeneralSettings());
  }

  getCCIASettings(): void {
    this.store.dispatch(OperationActions.getCciaSettings());
  }

  updateCCIASettings(cciaSettings: OperationSettingsCciaDto): void {
    this.store.dispatch(OperationActions.updateCciaSettings({ cciaSettings }));
  }

  resetCciaSettings(): void {
    this.store.dispatch(OperationActions.resetCciaSettings());
  }

  getPmIntegrationSettings(): void {
    this.store.dispatch(OperationActions.getPmIntegrationSettings());
  }

  updatePmIntegrationSettings(
    settings: OperationSettingsPmIntegrationDto
  ): void {
    this.store.dispatch(
      OperationActions.updatePmIntegrationSettings({ settings })
    );
  }

  resetPmIntegrationSettings(): void {
    this.store.dispatch(OperationActions.resetPmIntegrationSettings());
  }

  updateTagSettings(settings: OperationSettingsTagsDto): void {
    this.store.dispatch(OperationActions.updateTagSettings({ settings }));
  }

  updateTagFormatForm(settings: OperationSettingsTagsDto): void {
    this.store.dispatch(OperationActions.updateTagFormatForm({ settings }));
  }

  updateSortGroupSettings(settings: OperationSettingsSortGroupDto): void {
    this.store.dispatch(OperationActions.updateSortGroupSettings({ settings }));
  }

  reset(): void {
    this.store.dispatch(OperationActions.reset());
  }
}
