import { Injectable } from '@angular/core';
import { AdminPermissionsService } from '@ifhms/common/angular/data-access/admin-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, timeout } from 'rxjs/operators';
import { EnterprisePermissionsActions } from './enterprise-permissions.actions';

@Injectable()
export class EnterprisePermissionsEffects {

  private readonly loginTimeout = 10 * 1000; // 10 seconds

  constructor(
    private readonly actions$: Actions,
    private adminPermissionsService: AdminPermissionsService
  ) {
  }

  getEnterprisePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnterprisePermissionsActions.getEnterprisePermissions),
      exhaustMap(() =>
        this.adminPermissionsService.getEnterprisePermissionsForUser().pipe(
          map((enterprisePermissions) => EnterprisePermissionsActions.getEnterprisePermissionsSuccess({ enterprisePermissions })),
          timeout(this.loginTimeout),
          catchError(err => of(EnterprisePermissionsActions.error(err)))
        )
      )
    )
  );

}
