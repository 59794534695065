import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpParamsOptions
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  DataMigrationHistoryDto,
  DataMigrationTableListResponseDto,
  DataMigrationTableRequestDto
} from '@ifhms/models/admin';
import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class OfflineAccessManagementService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {}

  getTable(
    operationId: string,
    request: DataMigrationTableRequestDto
  ): Observable<DataMigrationTableListResponseDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: request as unknown as Record<string, any>
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<DataMigrationTableListResponseDto>(
      `${this.apiUrl}/Operation/${operationId}/OfflineAccess/DataMigration/Table`,
      options
    );
  }

  createMigration(operationId: string): Observable<string> {
    return this.http.get<string>(
      `${this.apiUrl}/Operation/${operationId}/OfflineAccess/DataMigration/StartMigration`
    );
  }

  getMigrationDetail(
    operationId: string,
    migrationId: string
  ): Observable<DataMigrationHistoryDto> {
    return this.http.get<DataMigrationHistoryDto>(
      `${this.apiUrl}/Operation/${operationId}/OfflineAccess/DataMigration/Detail/${migrationId}`,
      {}
    );
  }

  cancelMigration(operationId: string, migrationId: string): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/Operation/${operationId}/OfflineAccess/DataMigration/CancelMigration/${migrationId}`,
      {}
    );
  }

  clearMigrationData(operationId: string): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}/Operation/${operationId}/OfflineAccess/DataMigration/ClearMigrationData`,
      {}
    );
  }

  getIsDataMigrated(operationId: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.apiUrl}/Operation/${operationId}/OfflineAccess/DataMigration/IsDataMigrated`
    );
  }

  getIsDataMigrationInProgress(operationId: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.apiUrl}/Operation/${operationId}/OfflineAccess/DataMigration/IsDataMigrationInProgress`
    );
  }
}
