import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OperationRoleDto, OperationRoleUpdateDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable()
export class OperationRolePermissionsService {
  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private http: HttpClient
  ) {
  }

  get(feedlotId: string, id: string): Observable<OperationRoleDto> {
    return this.http.get<OperationRoleDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Permissions/${id}`);
  }

  update(feedlotId: string, roleUpdate: OperationRoleUpdateDto): Observable<OperationRoleDto> {
    return this.http.put<OperationRoleDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Permissions`, roleUpdate);
  }
}
