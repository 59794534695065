import { Injectable } from '@angular/core';
import { CompanyPermissionsDto } from '@ifhms/models/admin';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CompanyPermissionsActions } from './company-permissions.actions';

import * as reducer from './company-permissions.reducer';
import { PermissionsSelector } from './company-permissions.selectors';

@Injectable({ providedIn: 'root' })
export class CompanyPermissionsFacade {

  state$: Observable<reducer.State> = this.store.select(PermissionsSelector.selectState);
  companyPermissions$: Observable<CompanyPermissionsDto | null> = this.store.select(PermissionsSelector.selectCompanyPermissions);
  loading: Observable<boolean> = this.store.select(PermissionsSelector.selectLoading);
  loaded: Observable<boolean> = this.store.select(PermissionsSelector.selectLoaded);

  constructor(private store: Store<reducer.State>) {
  }

  getCompanyPermissions(forceReload = true, companyId?: string): void {
    this.store.dispatch(CompanyPermissionsActions.getCompanyPermissions({ forceReload, companyId }));
  }

  reset(): void {
    this.store.dispatch(CompanyPermissionsActions.reset());
  }
}
