import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';

import { CommonAngularPermissionsModule } from '@common/angular/permissions';

import {
  FrmPermissionFormGroupComponent,
  ModuleAccessFormGroupComponent,
  OperationPermissionFormGroupComponent,
  ProcurementPermissionFormGroupComponent
} from './components';
import { CompanyHasPermissionDirective, OperationHasPermissionDirective } from './directives';
import { OperationPermissionGuard } from './guards';
import { PermissionFieldConfigService } from './services';

@NgModule({
  imports: [
    CommonModule,
    CommonAngularPermissionsModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule
  ],
  declarations: [
    CompanyHasPermissionDirective,
    OperationHasPermissionDirective,
    FrmPermissionFormGroupComponent,
    ProcurementPermissionFormGroupComponent,
    ModuleAccessFormGroupComponent,
    OperationPermissionFormGroupComponent
  ],
  providers: [
    OperationPermissionGuard,
    PermissionFieldConfigService
  ],
  exports: [
    CompanyHasPermissionDirective,
    OperationHasPermissionDirective
  ]
})
export class IfhmsAdminWebDomainPermissionsModule {}
