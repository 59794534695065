import { OperationPermissionsDto } from '@ifhms/models/shared';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Operation Permissions]';

const getOperationPermissions = createAction(`${NAMESPACE} Get Operation Permissions`);
const getOperationPermissionsSuccess = createAction(`${NAMESPACE} Get Operation Permissions Success`,
  props<{ operationPermissions: OperationPermissionsDto; }>());

const reset = createAction(`${NAMESPACE} Reset`);

const error = createAction(`${NAMESPACE} Error`, props<{ error: any }>());

export const OperationPermissionsActions = {
  getOperationPermissions,
  getOperationPermissionsSuccess,
  reset,
  error
};
