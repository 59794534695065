import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';

import { scopeLoader } from '@common/angular/translation';

import { CommonHasPermissionDirective } from './directives';
import { PermissionDeniedComponent } from './components';
import { CommonAngularPermissionsRoutingModule } from './common-angular-permissions-routing.module';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    CommonAngularPermissionsRoutingModule
  ],
  declarations: [
    CommonHasPermissionDirective,
    PermissionDeniedComponent
  ],
  exports: [
    CommonHasPermissionDirective,
    PermissionDeniedComponent
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'permissions',
        alias: 'permissions',
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class CommonAngularPermissionsModule {}
