import { Inject, Injectable } from '@angular/core';
import { AuthFacade } from '@common/angular/auth';
import { SHOW_DEV_FEATURE } from '@common/angular/config';

import {
  CompanyPermissionsService,
  OperationalFeaturePermission,
  OperationPermissionsService
} from '@ifhms/admin/web/domain/permissions';
import { CompanyFacade } from '@ifhms/admin/web/domain/state/company';
import { OperationFacade } from '@ifhms/admin/web/domain/state/operation';
import { AbstractNavigationService } from '@ifhms/common/angular/features/navigation';
import { AuthAppLevelRoles } from '@ifhms/models/shared';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SersiNavListItem } from '@sersi/angular/ui/side-nav';
import { BehaviorSubject, combineLatest, map, Observable, of } from 'rxjs';
import {
  AdminNavigationMenu,
  CompanyMenuItemsFn,
  FeedlotMenuItemsFn,
  getCompanyNavigationMenuItems,
  getOperationNavigationMenuItems
} from './interfaces';
import { getEnterpriseNavigationMenuItems } from './interfaces/enterprise-navigation.constants';

@UntilDestroy()
@Injectable()
export class AdminNavigationService extends AbstractNavigationService {
  readonly translationScope = 'navigation';
  readonly translationNamespace = 'services.admin-navigation';

  showCompanyHeader$: Observable<boolean>;

  private showCompanyHeader = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(SHOW_DEV_FEATURE) private showDevFeature: boolean,
    private companyFacade: CompanyFacade,
    private operationFacade: OperationFacade,
    private operationPermissionsService: OperationPermissionsService,
    private companyPermissionsService: CompanyPermissionsService,
    private authFacade: AuthFacade
  ) {
    super();
    this.showCompanyHeader$ = this.showCompanyHeader.asObservable();

  }

  updateCompanyHeaderVisibity(isVisible: boolean): void {
    this.showCompanyHeader.next(isVisible);
  }

  getNavigationItems<AdminNavigationMenu>(
    menuType: AdminNavigationMenu
  ): Observable<SersiNavListItem[]> {
    let menuItems: Observable<SersiNavListItem[]>;
    switch (menuType) {
      case AdminNavigationMenu.Company:
        menuItems = this.withCompanyData(getCompanyNavigationMenuItems);
        break;
      case AdminNavigationMenu.Operation:
        menuItems = this.withOperationData(getOperationNavigationMenuItems);
        break;
      case AdminNavigationMenu.Enterprise:
        menuItems = this.withEnterpriseData();
        break;
      default:
        menuItems = of([]);
    }
    menuItems = menuItems.pipe(map((items) =>
      items.filter(item => item.isLegacyRoute ? !this.showDevFeature : true)
    ));
    return this.getLocalizedLabels(menuItems);
  }

  private withCompanyData(
    menuItemsFn: CompanyMenuItemsFn
  ): Observable<SersiNavListItem[]> {
    return this.companyFacade.companySlug$.pipe(
      map((companySlug) => {
        const menuItems = menuItemsFn(companySlug);
        return menuItems.filter((item) => {
          if (!item.requiredPermission) return true;

          return this.companyPermissionsService.hasPermissionSync(
            item.requiredPermission
          );
        });
      })
    );
  }

  private withOperationData(
    menuItemsFn: FeedlotMenuItemsFn
  ): Observable<SersiNavListItem[]> {
    return combineLatest([
      this.companyFacade.companySlug$,
      this.operationFacade.operationSlug$,
      this.authFacade.userRoles$,
      this.operationPermissionsService.permissions$
    ]).pipe(
      map(([companySlug, feedlotSlug, roles]) => {
        const isEnterprise = roles?.includes(AuthAppLevelRoles.Enterprise);
        const menuItems = menuItemsFn(
          companySlug,
          feedlotSlug,
          this.showDevFeature
        );
        return menuItems.filter((item) => {
          if (!this.showDevFeature && item.isVisible === false) return false;
          if (item.requiredModuleAccess && !this.operationPermissionsService.hasPermissionSync(item.requiredModuleAccess)) return false;
          if (!item.permissionList) return true;
          if (
            item.permissionList.some(
              (permission) => permission === 'enterprise'
            )
          ) {
            return isEnterprise;
          }
          return item.permissionList.some((permission) =>
            this.operationPermissionsService.hasPermissionSync(
              permission as OperationalFeaturePermission
            )
          );
        });
      })
    );
  }

  private withEnterpriseData(): Observable<SersiNavListItem[]> {
    const menuItems = getEnterpriseNavigationMenuItems();
    let visibleMenuItems = menuItems;
    if (!this.showDevFeature) {
      visibleMenuItems = this.filterMenuItems(menuItems);
    }
    return of(visibleMenuItems);
  }

  private filterMenuItems(items: SersiNavListItem[]): SersiNavListItem[] {
    return items.filter((item) => {
      if (item.isVisible === false) return false;
      if (item.subItems) {
        item.subItems = this.filterMenuItems(item.subItems);
        return item.subItems.length > 0;
      }
      return true;
    });
  }
}
