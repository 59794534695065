import { camelCaseToKebab } from './string.helper';

export interface TransposedTableRowData {
  header: string;
  values: string[] | number[];
}

export function transposeTableData(
  tableData: any,
  skipKey?: string
): TransposedTableRowData[] {
  return Object.keys(tableData[0]).map((key) =>
    key !== skipKey
      ? {
        header: camelCaseToKebab(key),
        values: tableData.map((row: any) => row[key])
      }
      : { header: '', values: [] }
  );
}
