<ng-container *transloco="let t; read: translateScope">
  <div class="p-grid p-justify-center p-align-center">
    <div class="p-col-12 p-md-6">
      <div class="p-card">
        <div class="p-card-body p-text-center">
          <h2>{{t('title')}}</h2>
          <p>{{t('message')}}</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>