import { inject, Injectable } from '@angular/core';
import { HashMap } from '@common/angular/interfaces';
import { getTranslationScope } from '@common/angular/translation';
import { camelCaseToKebab } from '@common/angular/utils';
import { ProviderScope, TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core/lib/models/fieldconfig';
import { FormlyTypesEnum } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';

@Injectable()
export class PermissionFieldConfigService {

  private providerScope = inject<ProviderScope>(TRANSLOCO_SCOPE);
  private translationService = inject(TranslocoService);

  scopedNamespace = `${getTranslationScope(this.providerScope)}.service.field-config`;

  private DEFAULT_CHECKBOX_CONFIG: FormlyFieldConfig = {
    type: FormlyTypesEnum.CHECKBOX,
    className: 'checkbox-item',
    resetOnHide: false,
    expressions: {
      'props.disabled': (field) => {
        const formState = field.options?.formState;
        return formState?.readonly || formState?.disabledKeys?.includes(field.key)
      },
      hide: field => !!field.options?.formState?.hiddenKeys?.includes(field.key)
    }
  }

  getReadPermissionFieldConfig(key: string, dependentFields: string[]): FormlyFieldConfig {
    return {
      key,
      ...this.DEFAULT_CHECKBOX_CONFIG,
      props: {
        label$: this.getTranslation$(key),
        onClick: (field: FormlyFieldConfig): void => {
          const isEnabling = field.model?.[key];
          if (!isEnabling) {
            this.updateRelatedPermissionFields(field, dependentFields, 'disable');
          }
        }
      }
    };
  }

  getWritePermissionFieldConfig(key: string, dependentFields: string[], fieldsToDisableWhenInactivated?: string[]): FormlyFieldConfig {
    return {
      key,
      ...this.DEFAULT_CHECKBOX_CONFIG,
      props: {
        label$: this.getTranslation$(key),
        onClick: (field: FormlyFieldConfig): void => {
          const isEnabling = field.model?.[key];
          if (isEnabling) {
            this.updateRelatedPermissionFields(field, dependentFields, 'enable');
          }
          if (!isEnabling && fieldsToDisableWhenInactivated?.length) {
            this.updateRelatedPermissionFields(field, fieldsToDisableWhenInactivated, 'disable');
          }
        }
      }
    };
  }

  getAccessPermissionFieldConfig(key: string): FormlyFieldConfig {
    return {
      key,
      ...this.DEFAULT_CHECKBOX_CONFIG,
      props: {
        label$: this.getTranslation$(key)
      }
    };
  }

  private updateRelatedPermissionFields(
    field: FormlyFieldConfig,
    dependentFields: string[],
    action: 'enable' | 'disable'
  ): void {
    const formValue = field.formControl?.value || {};
    const expectedFieldValue = action === 'enable';

    const fieldsToUpdate = dependentFields.filter(key => formValue[key] !== expectedFieldValue);

    if (!fieldsToUpdate.length) return;

    const formPatchValue = fieldsToUpdate.reduce((acc, fieldKey) => {
      acc[fieldKey] = expectedFieldValue;
      return acc;
    }, {} as HashMap<boolean>);

    field.form?.patchValue(<any>formPatchValue);
  }

  private getTranslation$(key: string): Observable<string> {
    return this.translationService.selectTranslate(`${this.scopedNamespace}.${camelCaseToKebab(key)}`);
  }
}