import { CompanyPermissionsDto } from '@ifhms/models/admin';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Company Permissions]';

const getCompanyPermissions = createAction(
  `${NAMESPACE} getCompanyPermissions`,
  props<{ forceReload: boolean, companyId?: string }>()
);
const getCompanyPermissionsSuccess = createAction(`${NAMESPACE} Get Company Permissions Success`,
  props<{ companyPermissions: CompanyPermissionsDto; companyId: string }>());

const reset = createAction(`${NAMESPACE} Reset`);

const error = createAction(`${NAMESPACE} Error`, props<{ error: any }>());

export const CompanyPermissionsActions = {
  getCompanyPermissions,
  getCompanyPermissionsSuccess,
  reset,
  error
};
