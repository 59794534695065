import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ah-common-permission-denied',
  templateUrl: './permission-denied.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionDeniedComponent {
  translateScope = 'permissions.components.access-denied';
}

