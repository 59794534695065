import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CompanyFacade } from '@ifhms/admin/web/domain/state/company';
import { AdminPermissionsService } from '@ifhms/common/angular/data-access/admin-api';
import { CompanyPermissionsService } from '@ifhms/admin/web/domain/permissions';
import { getPreviousValue, transformPermission } from '@common/angular/utils';
import { CompanyPermissionsDto } from '@ifhms/models/admin';
import { CommonPermissionsMap } from '@common/angular/permissions';

import { CompanyPermissionsActions } from './company-permissions.actions';
import { CompanyPermissionsFacade } from './company-permissions.facade';

@Injectable()
export class CompanyPermissionsEffects {

  constructor(
    private actions$: Actions,
    private companyFacade: CompanyFacade,
    private adminPermissionsService: AdminPermissionsService,
    private companyPermissionsService: CompanyPermissionsService,
    private companyPermissionsFacade: CompanyPermissionsFacade
  ) {}

  getCompanyPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPermissionsActions.getCompanyPermissions),
      withLatestFrom(getPreviousValue(this.companyPermissionsFacade.state$), this.companyFacade.companyId$),
      filter(([action, state, companyId]) => {
        const targetCompanyId = action.companyId || companyId;
        const isCompanyChange = state.companyId && targetCompanyId !== state.companyId;

        if (action.forceReload || isCompanyChange) return true;

        return !state.loaded && !state.loading;
      }),
      switchMap(([action,, companyId]) => {
        const _companyId = action.companyId || companyId;
        return this.adminPermissionsService.getCompanyPermissionsForUser(_companyId).pipe(
          tap(permissions => this.setCompanyPermissions(permissions)),
          map((companyPermissions) => CompanyPermissionsActions.getCompanyPermissionsSuccess({
            companyPermissions,
            companyId
          })),
          catchError(err => of(CompanyPermissionsActions.error(err)))
        )
      })
    )
  );

  private setCompanyPermissions(permissions: CompanyPermissionsDto): void {
    const flatPermissions: CommonPermissionsMap = transformPermission(permissions)!;
    this.companyPermissionsService.setPermissions(flatPermissions);
  }

}
