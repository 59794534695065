import {
  FrmFeaturePermission,
  OperationalFeaturePermission,
  OperationalPermissionsModuleAccess
} from '@ifhms/admin/web/domain/permissions';
import { NavListBaseItem } from '@ifhms/common/angular/features/navigation';

export const getOperationNavigationMenuItems = (
  companySlug: string,
  feedlotSlug: string
): NavListBaseItem[] => [
  {
    key: 'overview',
    icon: 'dashboard',
    active: false,
    routerLink: [`/operation/${companySlug}/detail/${feedlotSlug}/overview`]
  },
  {
    key: 'user-management',
    icon: 'person_outline',
    active: false,
    routerLink: [`/operation/${companySlug}/detail/${feedlotSlug}/user-management`],
    permissionList: [
      OperationalFeaturePermission.UsersView,
      OperationalFeaturePermission.RolesView
    ]
  },
  {
    key: 'reports',
    icon: 'insert_chart',
    active: false,
    routerLink: [`/operation/${companySlug}/detail/${feedlotSlug}/reports`],
    requiredModuleAccess: OperationalPermissionsModuleAccess.FRM,
    permissionList: [
      FrmFeaturePermission.ReportsAnimalTreatmentHistory,
      FrmFeaturePermission.ReportsDailyTreatmentHistoryByFacility,
      FrmFeaturePermission.ReportsLotUFDetail,
      FrmFeaturePermission.ReportsOtherDiseasesManagement,
      FrmFeaturePermission.ReportsPostMortemDetail,
      FrmFeaturePermission.ReportsPostMortemDetailByDate,
      FrmFeaturePermission.ReportsUFManagement,
      FrmFeaturePermission.ReportsCattleMovement,
      FrmFeaturePermission.ReportsEventDetails,
      FrmFeaturePermission.ReportsHospitalOccupancy,
      FrmFeaturePermission.ReportsLotExpensesSummary,
      FrmFeaturePermission.ReportsLotHealthSummary,
      FrmFeaturePermission.ReportsLotOtherDiseasesDetail,
      FrmFeaturePermission.ReportsLotInventoryReport,
      FrmFeaturePermission.ReportsPenInventory,
      FrmFeaturePermission.ReportsTagAvailability,
      FrmFeaturePermission.ReportsWithdrawal,
      FrmFeaturePermission.ReportsCurrentProductPricing,
      FrmFeaturePermission.ReportsProductOnHand,
      FrmFeaturePermission.ReportsProductUsage,
      FrmFeaturePermission.ReportsRailDetail,
      FrmFeaturePermission.ReportsReconciledPhysicalInventoryValue,
      FrmFeaturePermission.ReportsArrivalWorkOrder,
      FrmFeaturePermission.ReportsLotTransferWorkOrder,
      FrmFeaturePermission.ReportsMovementWorkOrder,
      FrmFeaturePermission.ReportsRailWorkOrder,
      FrmFeaturePermission.ReportsReHandlingWorkOrder,
      FrmFeaturePermission.ReportsShipmentWorkOrder
    ]
  },
  {
    key: 'product-management',
    icon: 'inventory_2',
    active: false,
    routerLink: [
      `/operation/${companySlug}/detail/${feedlotSlug}/product-management`
    ],
    requiredModuleAccess: OperationalPermissionsModuleAccess.FRM,
    permissionList: [
      FrmFeaturePermission.ProductDefinitionView,
      FrmFeaturePermission.ProductMappingView,
      FrmFeaturePermission.ProductPricingView,
      FrmFeaturePermission.ProductReceiptView,
      FrmFeaturePermission.ProductReconciliationView,
      FrmFeaturePermission.ProductLotNoExpiryView,
      FrmFeaturePermission.ProductTransactionView
    ]
  },
  {
    key: 'treatment-protocols',
    icon: 'medical_services',
    active: false,
    routerLink: [
      `/operation/${companySlug}/detail/${feedlotSlug}/treatment-protocols`
    ],
    requiredModuleAccess: OperationalPermissionsModuleAccess.FRM,
    permissionList: ['enterprise']
  },
  {
    key: 'reference-data',
    icon: 'data_object',
    active: false,
    routerLink: [
      `/operation/${companySlug}/detail/${feedlotSlug}/reference-data`
    ],
    permissionList: [OperationalFeaturePermission.ReferenceDataView]
  },
  {
    key: 'settings',
    icon: 'settings',
    active: false,
    routerLink: [`/operation/${companySlug}/detail/${feedlotSlug}/settings`],
    permissionList: [
      OperationalFeaturePermission.SettingsGeneralView,
      FrmFeaturePermission.SettingsPricingSettingsView,
      FrmFeaturePermission.SettingsDefaultValuesView,
      FrmFeaturePermission.SettingsArrivalView,
      FrmFeaturePermission.SettingsReHandlingView,
      FrmFeaturePermission.SettingsPostMortemView,
      FrmFeaturePermission.SettingsLabourChargesView,
      FrmFeaturePermission.SettingsProcedureChargesView,
      FrmFeaturePermission.SettingsCustomFieldsManagementView,
      FrmFeaturePermission.SettingsTagView,
      FrmFeaturePermission.SettingsSortGroupView,
      FrmFeaturePermission.SettingsSortGroupTemplatesView,
      FrmFeaturePermission.SettingsCCIAView
    ]
  },
  {
    key: 'lot-billing',
    icon: 'receipt_long',
    active: false,
    routerLink: [
      `/operation/${companySlug}/detail/${feedlotSlug}/lot-billing`
    ],
    requiredModuleAccess: OperationalPermissionsModuleAccess.FRM,
    permissionList: [FrmFeaturePermission.LotBillingView]
  },
  {
    key: 'offline-access',
    icon: 'cloud_download',
    active: false,
    isVisible: false,
    routerLink: [
      `/operation/${companySlug}/detail/${feedlotSlug}/offline-access/data-migration/overview`
    ],
    requiredModuleAccess: OperationalPermissionsModuleAccess.FRM,
    permissionList: [FrmFeaturePermission.LotBillingView] //temporary
  }
];
