import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LayoutFacade } from '../+state/layout.facade';
import { filter } from 'rxjs';
import { MenuType } from '../models/menu-type.enum';

@UntilDestroy()
@Injectable()
export class LayoutService {
  constructor(private router: Router,
    private layoutFacade: LayoutFacade) {
  }

  public init(): void {
    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      untilDestroyed(this)
    ).subscribe((event: any) => {

      const routes = event.urlAfterRedirects.toLowerCase().slice(1).split('/');

      if (routes.includes('company') && routes.includes('detail') && routes.includes('settings')) {
        this.layoutFacade.setMenuType(MenuType.FEEDLOT_SETTINGS);
      } else if (routes.includes('company') && routes.includes('detail')) {
        this.layoutFacade.setMenuType(MenuType.FEEDLOT);
      } else if (routes.includes('company')) {
        this.layoutFacade.setMenuType(MenuType.COMPANY);
      } else if (routes.includes('enterprise')) {
        this.layoutFacade.setMenuType(MenuType.ENTERPRISE);
      } else if (routes.includes('companies')) {
        this.layoutFacade.setMenuType(MenuType.MAIN);
      }

    });
  }
}
