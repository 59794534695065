import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OperationUserInviteDto, OperationUserListItemDto, OperationUserUpdateDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable()
export class OperationUserService {

  constructor(@Inject('apiUrl') private apiUrl: string, private http: HttpClient) {
  }

  getList(operationId: string): Observable<OperationUserListItemDto[]> {
    return this.http.get<OperationUserListItemDto[]>(`${this.apiUrl}/Operation/${operationId}/Users/List`);
  }

  invite(operationId: string, companyUserUpdateDto: OperationUserInviteDto): Observable<OperationUserListItemDto[]> {
    return this.http.put<OperationUserListItemDto[]>(`${this.apiUrl}/Operation/${operationId}/Users/Invite`, companyUserUpdateDto);
  }

  revoke(operationId: string, operationUserId: string): Observable<OperationUserListItemDto[]> {
    return this.http.put<OperationUserListItemDto[]>(`${this.apiUrl}/Operation/${operationId}/Users/${operationUserId}/Revoke`, {});
  }

  update(operationId: string, companyUserUpdateDto: OperationUserUpdateDto): Observable<OperationUserListItemDto[]> {
    return this.http.put<OperationUserListItemDto[]>(`${this.apiUrl}/Operation/${operationId}/Users`, companyUserUpdateDto);
  }

  isEmailValid(operationId: string, email: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: { email }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Operation/${operationId}/Users/IsEmailUnique`, options);
  }
}
