import { OperationPermissionsDto, ProcessMethod } from '@ifhms/models/shared';
import { AcquisitionType, ReceiptPricingMethod } from '../../enums';
import { FeedlotGeneralSettingsDto } from '../operation-settings';

export interface OperationDto {
  id: string;
  name: string;
  slug: string;
  countryId: string;
  companyName: string;
  permissions: OperationPermissionsDto;
  generalSettings: FeedlotGeneralSettingsDto;
  cciaAccountSettings: OperationSettingsCciaDto | null;
  pmIntegrationSettings: OperationSettingsPmIntegrationDto | null;
  location: OperationSettingsLocationDto;
  sortGroupSettings: OperationSettingsSortGroupDto;
  tagSettings: OperationSettingsTagsDto;
  globalSettings: FeedlotDefaultValuesSettingsDto;
  globalPricingSettings: FeedlotPricingSettingsDto;
  moduleIntegrationSettings: ModuleEnablementSettingsDto;
}

export interface ModuleEnablementSettingsDto {
  description: string,
  code: string,
  isFrmEnabled: boolean,
  isProcurementsEnabled: boolean,
  isPostMortemEnabled: boolean,
  isDSortEnabled: boolean,
  isItsGlobalEnabled: boolean,
  isFusionEnabled: boolean,
  isTurnKeyEnabled: boolean,
  isProFeederEnabled: boolean,
  isFeedYardEnabled: boolean,
  isCciaEnabled: boolean,
  isPsmtEnabled: boolean,
  postMortemApiKey: string
}

export interface FeedlotArrivalSettingsDto {
  acquisitionType?: AcquisitionType;
  feedingProgramId?: string;
  marketingPlanId?: string;
  breedTypeId?: string;
  sortTypeId?: string;
  processMethod?: ProcessMethod;
  recordWeight: boolean;
  recordTemperature: boolean;
  requireSource: boolean;
  requirePurchaser: boolean;
  requireNationalId: boolean;
  requireCoatColour: boolean;
  requireDCode: boolean;
  requireHipHeight: boolean;
  tagLocationId?: string;
  arrivalAddToMarketingProgramPsmt: boolean;
  startNewEventOnRfIdScan: boolean;
}

export interface FeedlotReHandlingSettingsDto {
  coatColor: boolean;
  dCode: boolean;
  hipHeight: boolean;
  nationalId: boolean;
}

export interface FeedlotPostMortemSettingsDto {
  disposalCharge: number;
}

// OperationSettingsGlobalDto
export interface FeedlotDefaultValuesSettingsDto {
  feedlotId: string;
  brokenNeedle: boolean;
  sortType: boolean;
  minWeight: number;
  maxWeight: number;
  maxAge: number;
  minimumTemperature: number;
  maximumTemperature: number;
}

// OperationSettingsGlobalPricingDto
export interface FeedlotPricingSettingsDto {
  feedlotId: string;
  pricingMethodId?: string;
  pricingMethodAmount: number;
  receiptPricingMethod: ReceiptPricingMethod;
}

export interface OperationSettingsCciaDto {
  id: string;
  username?: string;
  password?: string;
  accountId?: string;
  premises: OperationSettingsCciaPremiseDto[];
}

export interface OperationSettingsPmIntegrationDto {
  apiKey: string | null;
}

export interface OperationSettingsCciaPremiseDto {
  id: string;
  premiseId: string;
  isDefault: boolean;
}

export interface OperationSettingsLocationDto {
  latitude?: number;
  longitude?: number;
  directions?: string;
}

export interface OperationSettingsSortGroupDto {
  age: boolean;
  ageClass: boolean;
  breed: boolean;
  breedType: boolean;
  dCode: boolean;
  gender: boolean;
  temperature: boolean;
  weight: boolean;
}

export interface OperationSettingsTagsDto {
  prefixMin: number;
  prefixMax: number;
  numberMin: number;
  numberMax: number;
  suffixMin: number;
  suffixMax: number;
  regEx?: string;
}

export interface OperationSettingsTagsModel {
  prefixGroup: {
    prefixMin: number;
    prefixMax: number;
  };
  numberGroup: {
    numberMin: number;
    numberMax: number;
  };
  suffixGroup: {
    suffixMin: number;
    suffixMax: number;
  };
  regEx?: string;
}

export interface OperationCreateDto {
  name: string;
  code: string;
  countryId: string;
  companyId: string;
  operationTypeId: string;
}

export interface OperationListItemDto {
  id: string;
  description: string;
  code: string;
  operationTypeCode: OperationTypeCode;
}

export interface OperationUpdateDto {
  name: string;
  countryId: string;
  logo: string | null;
  logoMime: string | null;
}

export enum OperationTypeCode {
  Feedlot = 'FEEDLOT'
}
