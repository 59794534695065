export function roundNumber(value: number, precision: number): number {
  const multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
}

export function getNumberOrDefault(value: number | null, defaultVal = 0): number {
  return value || defaultVal;
}

export function getFixedDecimalNumber(value: number | null | undefined, precision = 2): string {
  return value?.toFixed(precision) || '-';
}
