import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CommonPermissionsMap, CommonPermissionsService } from '@common/angular/permissions';
import { getCurrentRouteWithData } from '@common/angular/utils';

@Injectable({ providedIn: 'root' })
export class CompanyPermissionsService extends CommonPermissionsService {

  constructor(
    private router: Router
  ) {
    super();
  }

  protected override getLoadedPermissions(): CommonPermissionsMap | null {
    return this.loadedPermissions || this.getRouteResolverPermissions();
  }

  private getRouteResolverPermissions(): CommonPermissionsMap | null {
    const routeData = getCurrentRouteWithData(this.router);
    return routeData['snapshot']?.data?.company?.permissions;
  }
}
