import { SersiNavListItem } from '@sersi/angular/ui/side-nav';

export const getEnterpriseNavigationMenuItems = (): SersiNavListItem[] => [
  {
    key: 'companies',
    icon: 'corporate_fare',
    active: true,
    routerLink: ['/enterprise/companies']
  },
  {
    key: 'user-management',
    icon: 'person_outline',
    active: false,
    routerLink: ['/enterprise/user-management'],
    subItems: [
      {
        key: 'users',
        icon: 'people',
        active: false,
        routerLink: ['/enterprise/user-management/users']
      },
      {
        key: 'roles',
        icon: 'group',
        active: false,
        routerLink: ['/enterprise/user-management/roles'],
        isVisible: false
      }
    ]
  },
  {
    key: 'reference-data',
    icon: 'data_object',
    active: false,
    routerLink: ['/enterprise/reference-data']
  },
  {
    key: 'module-enablement',
    icon: 'toggle_on',
    active: false,
    routerLink: ['/enterprise/module-enablement']
  },
  {
    key: 'frm',
    icon: 'vaccines',
    active: false,
    routerLink: ['/enterprise/frm'],
    subItems: [
      {
        key: 'diagnoses-settings',
        icon: 'settings',
        active: false,
        routerLink: ['/enterprise/frm/diagnosis']
      },
      {
        key: 'product-management',
        icon: 'inventory_2',
        active: false,
        routerLink: ['/enterprise/frm/product-management'],
        subItems: [
          {
            key: 'products',
            icon: 'inventory_2',
            active: false,
            routerLink: ['/enterprise/frm/product-management/products']
          }
        ]
      },
      {
        key: 'procedures',
        icon: 'list_alt',
        active: false,
        routerLink: ['/enterprise/frm/procedures']
      },
      {
        key: 'treatment-protocols',
        icon: 'medical_services',
        active: false,
        routerLink: ['/enterprise/frm/treatment-protocols'],
        isVisible: false
      },
      {
        key: 'other-settings',
        icon: 'settings',
        active: false,
        routerLink: ['/enterprise/frm/other-settings'],
        subItems: [
          {
            key: 'adg-settings',
            icon: 'inventory_2',
            active: false,
            routerLink: ['/enterprise/frm/other-settings/adg-settings']
          }
        ]
      }
    ]
  },
  {
    key: 'procurements',
    icon: 'content_paste_search',
    active: false,
    routerLink: ['/enterprise/procurements'],
    subItems: [
      // {
      //   key: 'settings',
      //   icon: 'settings',
      //   active: false,
      //   routerLink: ['/enterprise/procurements/settings']
      // },
      {
        key: 'param-estimates',
        icon: 'view_module',
        active: false,
        routerLink: ['/enterprise/procurements/parameter-estimates']
      },
      {
        key: 'p-codes',
        icon: 'view_list',
        active: false,
        routerLink: ['/enterprise/procurements/codes']
      }
    ]
  },
  {
    key: 'psmt',
    icon: 'price_check',
    active: false,
    routerLink: ['/enterprise/psmt'],
    subItems: [
      {
        key: 'settings',
        icon: 'settings',
        active: false,
        routerLink: ['/enterprise/psmt/settings']
      }
    ]
  },
  {
    key: 'dsort',
    icon: 'grid_view',
    active: false,
    routerLink: ['/enterprise/dsort'],
    subItems: [
      {
        key: 'settings',
        icon: 'settings',
        active: false,
        routerLink: ['/enterprise/dsort/settings']
      }
    ]
  },
  {
    key: 'release-notes',
    icon: 'data_object',
    active: false,
    routerLink: ['/enterprise/release-notes']
  }
];
