import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { getCurrentRouteDataFromSnapshot } from '@common/angular/utils';
import { ContextLevel } from '@ifhms/models/shared';
import { map, Observable, of } from 'rxjs';

import { OperationPermissionsService } from '../services';

@Injectable({ providedIn: 'root' })
export class OperationPermissionGuard {

  constructor(
    private operationPermissionsService: OperationPermissionsService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const routeData = getCurrentRouteDataFromSnapshot(state);
    if (routeData['contextLevel'] !== ContextLevel.Operation) {
      return of(true);
    }
    return this.operationPermissionsService.hasAnyPermission(route.data?.['permissionList'])
      .pipe(
        map(hasPermission => {
          if (hasPermission) return true;
          return this.router.parseUrl('/');
        })
      )
  }

}
