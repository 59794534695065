import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { getTranslationScope } from '@common/angular/translation';
import { ProviderScope, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { AbstractFormFieldConfigComponent, FormlyWrappersEnum } from '@sersi/angular/formly/core';

import { PermissionFieldConfigService } from '../../services';

@Component({
  selector: 'aa-admin-procurement-permission-form-group',
  templateUrl: './procurement-permission-form-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PermissionFieldConfigService]
})
export class ProcurementPermissionFormGroupComponent extends AbstractFormFieldConfigComponent {

  private fieldService = inject(PermissionFieldConfigService);
  private providerScope = inject<ProviderScope>(TRANSLOCO_SCOPE);

  protected override scopedNamespace = `${getTranslationScope(this.providerScope)}.components.procurement-permission`;

  constructor() {
    super();
    this.fieldService.scopedNamespace += '.procurement-permission';
  }

  protected getFieldGroupConfig(): FormlyFieldConfig[] {
    return [
      this.setGeneral(),
      this.setSettingsSection()
    ];
  }

  private setGeneral(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('general'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewProcurement', [
          'canEditProcurement',
          'canIssueProcurement'
        ]),

        this.fieldService.getWritePermissionFieldConfig('canEditProcurement', ['canViewProcurement'], ['canIssueProcurement']),

        this.fieldService.getWritePermissionFieldConfig('canIssueProcurement', [
          'canViewProcurement',
          'canEditProcurement'
        ])
      ]
    }
  }

  private setSettingsSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('settings'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewSettings', ['canEditSettings']),
        this.fieldService.getWritePermissionFieldConfig('canEditSettings', ['canViewSettings'])
      ]
    }
  }

}
