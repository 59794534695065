import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { getTranslationScope } from '@common/angular/translation';
import { ProviderScope, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { AbstractFormFieldConfigComponent, FormlyWrappersEnum } from '@sersi/angular/formly/core';

import { PermissionFieldConfigService } from '../../services';

@Component({
  selector: 'aa-admin-operation-permission-form-group',
  templateUrl: './operation-permission-form-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PermissionFieldConfigService]
})
export class OperationPermissionFormGroupComponent extends AbstractFormFieldConfigComponent {

  private fieldService = inject(PermissionFieldConfigService);
  private providerScope = inject<ProviderScope>(TRANSLOCO_SCOPE);

  protected override scopedNamespace = `${getTranslationScope(this.providerScope)}.components.operation-permission`;

  constructor() {
    super();
    this.fieldService.scopedNamespace += '.operation-permission';
  }

  protected getFieldGroupConfig(): FormlyFieldConfig[] {
    return [
      this.setGeneralSettingsSection(),
      this.setReferenceDataSection(),
      this.setUserManagementSection()
    ];
  }

  private setUserManagementSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        subHeading: true,
        label$: this.getTranslation$('user-management')
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewUsers', ['canEditUsers']),
        this.fieldService.getWritePermissionFieldConfig('canEditUsers', ['canViewUsers']),
        this.fieldService.getReadPermissionFieldConfig('canViewRoles', ['canEditRoles']),
        this.fieldService.getWritePermissionFieldConfig('canEditRoles', ['canViewRoles'])
      ]
    }
  }

  private setReferenceDataSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        subHeading: true,
        label$: this.getTranslation$('reference-data')
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewReferenceData', ['canEditReferenceData']),
        this.fieldService.getWritePermissionFieldConfig('canEditReferenceData', ['canViewReferenceData'])
      ]
    }
  }

  private setGeneralSettingsSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        subHeading: true,
        label$: this.getTranslation$('general-settings')
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewGeneralSettings', ['canEditGeneralSettings']),
        this.fieldService.getWritePermissionFieldConfig('canEditGeneralSettings', ['canViewGeneralSettings'])
      ]
    }
  }

}
